import React, { useEffect, useRef } from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import glb1 from './resources/tree_animate.glb'
import gsap from "gsap";


export function Tree(props) {
  const treeref=useRef()

  useEffect(()=>{
    gsap.to(group.current.scale,{
        scrollTrigger:{
            scrub:2
        },
        y:1.5,
        x:1,
        z:1
    })

    gsap.to(group.current.position,{
      scrollTrigger:{
          scrub:2
      },
      z:'-=130'
  })
})


  const group = useRef();
  const { nodes, materials, animations } = useGLTF(glb1);
  const { actions } = useAnimations(animations, group);
  const name=Object.keys(actions)

  useEffect(()=>{
    actions[name[0]].play()
    actions[name[0]].loop=true
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="Bark_9_2">
                <mesh
                  name="Object_4"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_4.geometry}
                  material={materials.Bark}
                />
              </group>
              <group name="Leaf_6_3">
                <mesh
                  name="mesh_1_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.mesh_1_1.geometry}
                  material={materials.Branch}
                  morphTargetDictionary={nodes.mesh_1_1.morphTargetDictionary}
                  morphTargetInfluences={nodes.mesh_1_1.morphTargetInfluences}
                />
                <mesh
                  name="mesh_1"
                  castShadow
                  receiveShadow
                  geometry={nodes.mesh_1.geometry}
                  material={materials.Leaf}
                  morphTargetDictionary={nodes.mesh_1.morphTargetDictionary}
                  morphTargetInfluences={nodes.mesh_1.morphTargetInfluences}
                />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(glb1);