import { createRoot } from "react-dom/client"
import App from "./App"
import "./styles.css"

createRoot(document.getElementById("root")).render(
<>
<App />
<div id="loader">
<svg width="300" height="120" id="clackers">
  <svg>
    <path id="arc-left-up" fill="none" d="M 90 90 A 90 90 0 0 1 0 0"/>
  </svg>
  <svg>
    <path id="arc-right-up" fill="none" d="M 100 90 A 90 90 0 0 0 190 0"/>
  </svg>
  
  <text x="150" y="50" fill="#ffffff" fontFamily="Helvetica Neue,Helvetica,Arial" fontSize="18"
        textAnchor="middle">
    L O A D I N G
  </text>
  <circle cx="15" cy="15" r="15">
    <animateMotion dur="1.5s" repeatCount="indefinite"
      calcMode="linear"
      keyPoints="0.0;0.19;0.36;0.51;0.64;0.75;0.84;0.91;0.96;0.99;1.0;0.99;0.96;0.91;0.84;0.75;0.64;0.51;0.36;0.19;0.0;0.0;0.05;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0"
      keyTimes="0.0;0.025;0.05;0.075;0.1;0.125;0.15;0.175;0.2;0.225;0.25;0.275;0.3;0.325;0.35;0.375;0.4;0.425;0.45;0.475;0.5;0.525;0.55;0.575;0.6;0.625;0.65;0.675;0.7;0.725;0.75;0.775;0.8;0.825;0.85;0.875;0.9;0.925;0.95;0.975;1.0">
      <mpath xlinkHref="#arc-left-up"/>
    </animateMotion>
  </circle>
  <circle cx="135" cy="105" r="15" />
  <circle cx="165" cy="105" r="15" />
  <circle cx="95" cy="15" r="15">
    <animateMotion dur="1.5s" repeatCount="indefinite"
      calcMode="linear"
      keyPoints="0.0;0.0;0.05;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0.0;0.19;0.36;0.51;0.64;0.75;0.84;0.91;0.96;0.99;1.0;0.99;0.96;0.91;0.84;0.75;0.64;0.51;0.36;0.19;0.0"
      keyTimes="0.0;0.025;0.05;0.075;0.1;0.125;0.15;0.175;0.2;0.225;0.25;0.275;0.3;0.325;0.35;0.375;0.4;0.425;0.45;0.475;0.5;0.525;0.55;0.575;0.6;0.625;0.65;0.675;0.7;0.725;0.75;0.775;0.8;0.825;0.85;0.875;0.9;0.925;0.95;0.975;1.0">
      <mpath xlinkHref="#arc-right-up"/>
    </animateMotion>
  </circle>
</svg>
</div>
<div id='whiteout'></div>
<div id='introtext'>
<div id='MainText'>
WE<span style={{ color:'rgba(251, 252, 255, 0.555)'}}>L</span>COME T<span style={{ color:'rgba(251, 252, 255, 0.555)'}}>O</span> MY <br/>J<span style={{color:'rgba(251, 252, 255, 0.555)'}}>OU</span>RN<span style={{color:'rgba(251, 252, 255, 0.555)'}}>E</span>Y 
</div>
</div>

<div id='info1'>F<span style={{fontStyle:'italic',color:'rgba(251, 252, 255, 0.85)'}}>5</span>0 ST<span style={{fontStyle:'italic',color:'rgba(251, 252, 255, 0.8)'}}>A<span style={{fontStyle:'italic',color:'rgba(251, 252, 255, 0.6)'}}>R</span></span><span style={{fontStyle:'italic',color:'rgba(5, 32, 105, 0.7)'}}>T</span>UP ACCELERATOR<br/><span style={{fontSize:'15px', color:'rgba(10, 10, 10, 0.7)'}}>F50, Marketing Associate</span><br/><a href="http://f50.io" target='blank'>Click To Know More</a></div>
<div id='info2'>K<span style={{color:'rgba(251, 252, 255, 0.85)'}}>O</span><span style={{color:'rgba(5, 32, 105, 0.6)'}}>R</span><span style={{fontStyle:'italic',color:'rgba(251, 252, 255, 0.65)'}}>A</span> KOR<span style={{fontStyle:'italic',color:'rgba(251, 252, 255, 0.85)'}}>A</span><br/><span style={{fontSize:'15px',color:'rgba(10, 10, 10, 0.7)'}}>Kora Kora, Co-Founder</span><br/><a href="https://korakora.com.br" target='blank'>Click To Know More</a></div>
<div id='info3'>WO<span style={{color:'rgba(251, 252, 255, 0.85)'}}>M<span style={{fontStyle:'italic',color:'rgba(251, 252, 255, 0.65)'}}>E</span></span>N <br/>OF CR<span style={{color:'rgba(251, 252, 255, 0.75)'}}>Y</span>PTO<br/><span style={{fontSize:'15px',color:'rgba(10, 10, 10, 0.7)'}}>Women of Crypto, Head of Marketing</span><br/><a href="https://womenofcrypto.io" target='blank'>Click To Know More</a></div>
<div id='info4'>R<span style={{fontStyle:'italic', color:'rgba(251, 252, 255, 0.85)'}}>A</span>F<span style={{color:'rgba(251, 252, 255, 0.85)'}}>I</span> L<span style={{color:'rgba(251, 252, 255, 0.7)'}}>O</span><span style={{color:'rgba(251, 252, 255, 1)'}}>U</span>NGE<br/><span style={{fontSize:'15px',color:'rgba(10, 10, 10, 0.7)'}}>Rafi Lounge, Operations & Design</span><br/><a href="https://www.rafilounge.com" target='blank'>Click To Know More</a></div>
<div id='blank'></div>
<div id='blank'></div>
<div id='blank'></div>
<div id='blank'></div>
<div id="blank"></div>
<div id="blank"></div>
</>
)
