import React, { Suspense, useEffect, useRef } from "react";
import { MeshReflectorMaterial, shaderMaterial, useGLTF } from "@react-three/drei";
import * as THREE from 'three'
import glb2 from './resources/walkwayF2.glb'
import { extend, useFrame, useLoader } from "@react-three/fiber";
import { DoubleSide, ShaderMaterial, sRGBEncoding, TextureLoader, Vector2 } from "three";
import glsl from 'babel-plugin-glsl/macro'
import { degToRad } from "three/src/math/MathUtils";




export function Model3(props) {
  const { scene,nodes} = useGLTF(glb2);
  const ledge=useRef()

  useFrame((state)=>{
    scene.children[0].material.uniforms.u_time.value=state.clock.getElapsedTime()
  })

  const Mat2 =new ShaderMaterial({
    uniforms:{
      u_resolution: {value:new Vector2(window.innerWidth,window.innerHeight)},
      u_time:{value:1}
    },
    vertexShader:`
    precision mediump float;
 
    varying vec2 vUv;
    varying float vWave;
    varying vec3 vPosition;
    uniform float u_time;
    
    

    void main() {
      vUv = uv;
      vPosition=position;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);  
    }
  `,
   fragmentShader:`precision mediump float;
   varying vec3 vPosition;
   uniform vec3 uColor;
   uniform float u_time;
   uniform sampler2D uTexture;
   float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
   vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
   vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}

   float snoise3(vec3 p){
    vec3 a = floor(p);
    vec3 d = p - a;
    d = d * d * (3.0 - 2.0 * d);

    vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
    vec4 k1 = perm(b.xyxy);
    vec4 k2 = perm(k1.xyxy + b.zzww);

    vec4 c = k2 + a.zzzz;
    vec4 k3 = perm(c);
    vec4 k4 = perm(c + 1.0);

    vec4 o1 = fract(k3 * (1.0 / 41.0));
    vec4 o2 = fract(k4 * (1.0 / 41.0));

    vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
    vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);

    return o4.y * d.y + o4.x * (1.0 - d.y);
}
   
   
   varying vec2 vUv;
   
   void main() {
     vec3 color4= vec3(0.8,0.8,0.8);
     vec3 color3= vec3(0.0,0.0,0.0);
     vec3 anim= vec3(vPosition.x*2.,vPosition.y*0.1,vPosition.z*0.03);
     float n=snoise3(anim+u_time*0.21);
     
     vec3 bColor= mix(color4,color3,n);
     gl_FragColor = vec4(bColor,1.0);}`})

  useEffect(()=>{
    scene.children[0].material=Mat2
  })


  return (
      <Suspense fallback={null}>
        <primitive object={scene} {...props}/>
      </Suspense>
  );
}


/*export function Model3(props) {
  const { nodes} = useGLTF(glb2);

  const t1=useLoader(THREE.TextureLoader,texture1)
  t1.encoding=THREE.sRGBEncoding
  t1.flipY=false

  const mat1=new THREE.MeshBasicMaterial({map:t1})
  const ledge=useRef()
  const ledge1=useRef()


  useFrame((state)=>{
    ledge.current.u_time=state.clock.getElapsedTime()
    ledge1.current.u_time=state.clock.getElapsedTime()
  })
  const Mat2 = shaderMaterial(
    {
      u_resolution: new Vector2(window.innerWidth,window.innerHeight),
      u_time:1,
      u_Channel0: t1
    },
    glsl`
    precision mediump float;
 
    varying vec2 vUv;
    varying float vWave;
    varying vec3 vPosition;
    uniform float u_time;
    
    

    void main() {
      vUv = uv;
      vPosition=position;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);  
    }
  `,
   glsl`precision mediump float;
   varying vec3 vPosition;
   uniform vec3 uColor;
   uniform float u_time;
   uniform sampler2D uTexture;
   #pragma glslify: snoise3 = require(glsl-noise/simplex/3d.glsl);
   
   
   varying vec2 vUv;
   
   void main() {
     vec3 color4= vec3(0.8,0.8,0.8);
     vec3 color3= vec3(0.0,0.0,0.0);
     vec3 anim= vec3(vPosition.x*0.1,vPosition.y*4.,vPosition.z*0.03);
     float n=snoise3(anim+u_time*0.1);
     
     vec3 bColor= mix(color4,color3,n);
     gl_FragColor = vec4(bColor,1.0);}`)

  extend({Mat2})

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008.geometry}
        position={[2.66, 0.3, 0.79]}
      >
        <mat2 attach="material" ref={ledge}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        position={[-4.18, 0.3, 0.79]}>
          <mat2 attach="material" ref={ledge1}/>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={mat1}
        position={[2.63, 0.3, -0.78]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003.geometry}
        material={mat1}
        position={[-4.34, 0.3, -0.78]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={mat1}
        position={[-11.32, 0.3, -0.78]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005.geometry}
        material={mat1}
        position={[-11.15, 0.3, 0.79]}
      />
    </group>
  );
}*/