import React, { useRef } from "react";
import { shaderMaterial, useGLTF } from "@react-three/drei";
import glb1 from './resources/mountain.glb'
import * as THREE from 'three'
import { extend, useFrame } from "@react-three/fiber";
import { DoubleSide } from "three";

export function Cliff(props) {
    const mat1=useRef()
    const ShaderMat1=shaderMaterial(
        // Uniform
        {
          u_time: 0,
          uColor: new THREE.Color(2.0, 5.0, 6.0),
          uTexture: new THREE.Texture()
        },
        // Vertex Shader
        `
                  precision highp float;
               
                  varying vec2 vUv;
                  varying vec3 vPosition;
                  
                  
              
                  void main() {
                    vUv = uv;
                    vPosition=position;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);  
                  }
                `,
        // Fragment Shader
        ` 
        precision highp float;
        uniform float u_time;
        varying vec2 vUv;
        varying vec3 vPosition;
    
        float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
        vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
        vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}
    
        float noise(vec3 p){
            vec3 a = floor(p);
            vec3 d = p - a;
            d = d * d * (3.0 - 2.0 * d);
    
            vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
            vec4 k1 = perm(b.xyxy);
            vec4 k2 = perm(k1.xyxy + b.zzww);
    
            vec4 c = k2 + a.zzzz;
            vec4 k3 = perm(c);
            vec4 k4 = perm(c + 1.0);
    
            vec4 o1 = fract(k3 * (1.0 / 41.0));
            vec4 o2 = fract(k4 * (1.0 / 41.0));
    
            vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
            vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);
    
            return o4.y * d.y + o4.x * (1.0 - d.y);
        }
    
        void main() {
            
            vec3 firstColor= vec3(1.,1.,1.);
            vec3 secondColor= vec3(1.,1.,1.0);
            vec3 thirdColor= vec3(.1,0.1,0.8);
            vec3 baseUV=vPosition*0.001;
            float n=noise(baseUV+u_time*0.4);
            vec3 mainColor = mix(firstColor,secondColor,n);
            vec3 fColor = mix(mainColor,thirdColor,n);
            gl_FragColor=vec4(fColor,1.);
    }`)
    
            extend({ShaderMat1})
  
  
  
  const { nodes } = useGLTF(glb1);


  useFrame((state)=>{
    mat1.current.uniforms.u_time.value=state.clock.getElapsedTime()
})




  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.12}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3.geometry}
        >
            <shaderMat1 attach="material" ref={mat1} side={DoubleSide}/>
        </mesh>
      </group>
    </group>
  );
}