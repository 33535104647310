import React, { Suspense, useEffect, useMemo, useRef, useState } from "react"
import { Canvas, extend, useFrame, useLoader, useThree } from "@react-three/fiber"
import { Sky, OrbitControls, Stats, shaderMaterial, Effects, Cloud, Environment, PerspectiveCamera, MeshReflectorMaterial, Stars, useProgress, useGLTF, Select } from "@react-three/drei"
import Grass from "./Grass"
import * as THREE from 'three'
import { degToRad, lerp } from "three/src/math/MathUtils"
import { DoubleSide, RGBADepthPacking, sRGBEncoding, TextureLoader, Vector2 } from "three"
import { Model3, Model4 } from "./walkway"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import {Bloom, EffectComposer, Noise} from '@react-three/postprocessing'
import { ManWalk } from "./manModel"
import { Tree } from "./TreeAnim"
import { Water } from 'three/examples/jsm/objects/Water';
import p1T from './resources/Portfolio-011.jpg'
import p2T from './resources/Portfolio-021.jpg'
import p3T from './resources/Portfolio-031.jpg'
import p4T from './resources/Portfolio-041.jpg'
import { Cliff } from "./Mountain"
import SplitType from "split-type"
import glbw from './resources/walkwayFF2.glb'


extend({Water})

gsap.registerPlugin(ScrollTrigger)




const CameraController =()=>{

  const [mobileV,setV] = useState(false)

  const {camera}=useThree()
  camera.position.z=50
  camera.position.y=1.4
  camera.rotation.x=degToRad(10)

  const cursor = {}
  cursor.x = 0
  cursor.y = 0

  const scroll={}
  scroll.y=0
  const onMoScroll = (event) => {
    cursor.x = event.clientX / window.innerWidth - 0.5
    cursor.y = event.clientY / window.innerHeight - 0.5
  }
  window.addEventListener("mousemove", onMoScroll)

  window.addEventListener("scroll",()=>{
    scroll.y=window.scrollY/window.innerHeight
  })

  let startWhite=0
  let endWhite=0

  useFrame((state, delta) => {
    camera.position.x += (cursor.x - camera.position.x) * 0.05
    //camera.position.y += (-cursor.y - camera.position.y) * 0.05

    document.getElementById('whiteout').style.opacity=lerp(document.getElementById('whiteout').style.opacity,camera.position.z<5 && camera.position.z>-10?1:0.01,0.1)
    document.getElementById('info1').style.opacity=lerp(document.getElementById('info1').style.opacity,camera.position.z<42 && camera.position.z>35?1:0.01,0.1)
    document.getElementById('info2').style.opacity=lerp(document.getElementById('info2').style.opacity,camera.position.z<33 && camera.position.z>25?1:0.01,0.1)
    document.getElementById('info3').style.opacity=lerp(document.getElementById('info3').style.opacity,camera.position.z<22 && camera.position.z>13?1:0.01,0.1)
    document.getElementById('info4').style.opacity=lerp(document.getElementById('info4').style.opacity,camera.position.z<12 && camera.position.z>4?1:0.01,0.1)

    if(scroll.y>3){
      camera.position.z=-1000
    }
  })

  

  
  useEffect(()=>{
    const tl2=gsap.timeline({
      scrollTrigger: {
        scrub: 2
      }
    })
    
    tl2.to(camera.position,{
      z: -12
    })

    console.log(startWhite)
  },[])
}

const PortalM = shaderMaterial({
  utime:0,
  u_resolution: new Vector2(),
  uPixelRatio: Math.min(window.devicePixelRatio, 2),
  uScroll: 0,
  uRatio: window.innerHeight/window.innerWidth
},
  `
#ifdef GL_ES
precision mediump float;
#endif
uniform float utime;
uniform vec2 u_resolution;

void main(){
  vec4 modelPosition=modelMatrix*vec4(position,1.0);
  vec4 viewPosition=viewMatrix*modelPosition;
  gl_Position=projectionMatrix*viewPosition;
}
`,
` 
#ifdef GL_ES
precision mediump float;
#endif
uniform vec2 u_resolution;
uniform float utime;
uniform float uScroll;
uniform float uRatio;

void main(){

vec2 fCoord = vec2(gl_FragCoord.x/u_resolution)-uRatio-0.6;

float distanceT = distance(fCoord,vec2(0.85));
float strength = 01./distanceT-1.7;
gl_FragColor=vec4(1.,1.,1.,strength+uScroll/1.3);
}
  `,
  (self)=>{
    self.blending=THREE.NormalBlending
    self.transparent=true
    self.depthWrite=false
  })


  extend({PortalM})


  let scrollY={}
  scrollY.len=0

const Portal=()=>{

  const glow= useRef()
  const {progress}=useProgress()

  useEffect(()=>{gsap.fromTo(scrollY,{len:0},{
    scrollTrigger:{
      scrub:2
    },
    len:3
  })

  if(progress>=100){
  gsap.to('#loader',{
    opacity:0,
    duration:1.5
  })
}

},[])

  useFrame((state)=>{
    glow.current.uniforms.u_resolution.value = new Vector2(750,600)
    glow.current.uniforms.utime.value=state.clock.getElapsedTime()
    glow.current.uniforms.uScroll.value=scrollY.len
  })

  const {camera}=useThree()

  return (
    <mesh position={[0,6,-40]} >
      <planeBufferGeometry args={[700,500,1,1]}/>
      <portalM attach="material" ref={glow}/>
    </mesh>
  )}


  /*const ProjectPanels=()=>{
    const p1=useRef()
    const {camera}=useThree()

    const [clicked,click]=useState(false)
    
    return(
      <>
      <mesh position={[-35,10,20]} ref={p1} rotation={[degToRad(5),degToRad(25),0]}>
      <planeBufferGeometry args={[10,10]}/>
      <meshBasicMaterial color={0xffffff}/>
      </mesh>
    </>);
  }*/


  const WalkPlane=(props)=>{
  const { nodes, materials } = useGLTF(glbw);

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials["Material.004"]}
        position={[0, 0, 0]}
      />
    </group>
  );
  }

  const Stars1=()=>{
    const stref=useRef()

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

    useEffect(()=>{
      stref.current.position.z=-900
    })

    useFrame(()=>{
      stref.current.rotation.y+=0.0001
    })

    return(
      <group dispose={null}>
      <Stars count={1000} ref={stref} saturation={10} depth={100} fade speed={1}/>
       <Stars count={1000} fade speed={1}/>
      </group>
      
    )
  }


  const ProjectsPanel=()=>{
    //const texture1=
    const p1 = useRef()
    const p2 = useRef()
    const p3 = useRef()
    const p4 = useRef()

  const texture1 = useLoader(THREE.TextureLoader,p1T);
  const texture2 = useLoader(THREE.TextureLoader,p2T);
  const texture3 = useLoader(THREE.TextureLoader,p3T);
  const texture4 = useLoader(THREE.TextureLoader,p4T);
  const {camera}=useThree()

  

// Set up the shader material with the custom fragment shader
const material1 = new THREE.ShaderMaterial({
  uniforms: {
    // Set up uniforms for the two UV sets and the texture
    uColor:{value:''},
    uTexture:{value: texture1},
    uTime:{value:0},
    opacity:{value: 0}
  },
  vertexShader:`
  precision mediump float;

  varying vec2 vUv;
  varying float vWave;

  uniform float uTime;

  float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}

float snoise3(vec3 p){
    vec3 a = floor(p);
    vec3 d = p - a;
    d = d * d * (3.0 - 2.0 * d);

    vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
    vec4 k1 = perm(b.xyxy);
    vec4 k2 = perm(k1.xyxy + b.zzww);

    vec4 c = k2 + a.zzzz;
    vec4 k3 = perm(c);
    vec4 k4 = perm(c + 1.0);

    vec4 o1 = fract(k3 * (1.0 / 41.0));
    vec4 o2 = fract(k4 * (1.0 / 41.0));

    vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
    vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);

    return o4.y * d.y + o4.x * (1.0 - d.y);
}


  void main() {
    vUv = uv;

    vec3 pos = position;
    float noiseFreq = 0.1;
    float noiseAmp = 0.2;
    vec3 noisePos = vec3(pos.x * noiseFreq + uTime, pos.y, pos.z);
    pos.z += snoise3(noisePos) * noiseAmp;
    vWave = pos.z;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);  
  }
`
  ,
  fragmentShader: `
  precision mediump float;

  uniform vec3 uColor;
  uniform float uTime;
  uniform sampler2D uTexture;
  uniform float opacity;

  varying vec2 vUv;
  varying float vWave;

  void main() {
    vec3 texture = texture2D(uTexture, vUv ).rgb;
    gl_FragColor = vec4(texture, opacity); 
  }
`,
});

const material2=material1.clone()
const material3=material1.clone()
const material4=material1.clone()

const {progress}= useProgress()

const look1= new THREE.Vector3(0,7,0)

useEffect(()=>{
  p2.current.material.uniforms.uTexture.value=texture3
  p3.current.material.uniforms.uTexture.value=texture2
  p4.current.material.uniforms.uTexture.value=texture4

  const Wtext=new SplitType('#MainText')

  if(progress>=100)
  {
  document.documentElement.style.overflowY="auto"
  gsap.to('.char',
  { y:0,
    stagger:0.075,
    duration:1
  })}


  
})

useFrame((state)=>{
  p1.current.material.uniforms.uTime.value=state.clock.getElapsedTime()
  p1.current.material.transparent=true
  p2.current.material.uniforms.uTime.value=state.clock.getElapsedTime()
  p2.current.material.transparent=true
  p3.current.material.uniforms.uTime.value=state.clock.getElapsedTime()
  p3.current.material.transparent=true
  p4.current.material.uniforms.uTime.value=state.clock.getElapsedTime()
  p4.current.material.transparent=true

  p1.current.material.uniforms.opacity.value=lerp(p1.current.material.uniforms.opacity.value,camera.position.z<45 && camera.position.z>35?1:0,0.1)
  p2.current.material.uniforms.opacity.value=lerp(p2.current.material.uniforms.opacity.value,camera.position.z<35 && camera.position.z>25?1:0,0.1)
  p3.current.material.uniforms.opacity.value=lerp(p3.current.material.uniforms.opacity.value,camera.position.z<25 && camera.position.z>15?1:0,0.1)
  p4.current.material.uniforms.opacity.value=lerp(p4.current.material.uniforms.opacity.value,camera.position.z<15 && camera.position.z>5?1:0,0.1)

  if(camera.position.z<45){
    gsap.to('#MainText',{
      opacity:0,
      duration:1
    })
  }else{
    gsap.to('#MainText',{
      opacity:1,
      duration:1
    })
  }
})

    return (
      <group>
        <mesh
        material={material1}
        ref={p1}
        position={[0,8,32]}
        rotation={[degToRad(5),0,0]}
        >
          <planeBufferGeometry args={[10,10,10,7]}  />
        </mesh>
        <mesh
        material={material2}
        ref={p2}
        position={[0,8,22]}
        rotation={[degToRad(5),0,0]}
        >
          <planeBufferGeometry args={[10,10,10,7]}  />
        </mesh>
        <mesh
        material={material3}
        ref={p3}
        position={[0,8,12]}
        rotation={[degToRad(5),0,0]}
        >
          <planeBufferGeometry args={[10,10,10,7]}  />
        </mesh>
        <mesh
        material={material4}
        ref={p4}
        position={[0,8,2]}
        rotation={[degToRad(5),0,0]}
        >
          <planeBufferGeometry args={[10,10,10,7]}  />
        </mesh>
      </group>
        
    )
  }



export default function App() {
  return (
    <div id="ll">
    <Canvas camera={{fov:100,near:1, far:500}} dpr={2}>
      <ambientLight />
      <ProjectsPanel/>
      <Model3 rotation={[0,degToRad(90),0]} scale={[2,2.2,4]} position={[0,-1.8,-15]}/>
      <Suspense fallback={null}>
        <Grass position={[0,-4,-10]}/>
        <Portal/>
        <ManWalk rotation={[0,degToRad(180),0]} position={[0,0.05,50]} scale={1}/>
        <Tree position={[0,-5,-60]} rotation={[0,degToRad(90),0]} scale={0.2}/>
        <WalkPlane position={[0, -2, 29]} rotation={[0,degToRad(90),0]} scale={[2,2.2,4]}/>
        <WalkPlane position={[0, -2, -12.6]} rotation={[0,degToRad(90),0]} scale={[2,2.2,4]}/>
        <Cliff position={[-250,-5,-1250]} rotation={[0,degToRad(90),0]} scale={[1,3,1]}/>
      </Suspense>
      <Environment preset="night"/>
      <Sky sunPosition={[10,30,2]}/>
      <Stars1/>
      <CameraController/>
    </Canvas>
  </div>
  )

  //<Cloud segments={80} depth={10} width={400} opacity={1} color={0xfffff6} position={[10,70,-100]} transparent={true}/>
}
